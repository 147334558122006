<template>
	<div
		class="icon-wrap mint d-flex align-center justify-center pointer"
		@click="closeModal"
	>
		<div class="first-line line violet" />
		<div class="last-line line violet" />
	</div>
</template>

<script>
export default {
	name: 'CloseModal',
	methods: {
		closeModal () {
			this.$root.$emit('closeModalWindow')
		}
	}
}
</script>

<style lang="scss" scoped>
.icon-wrap {
	border-radius: 50%;
	width: 30px;
	height: 30px;
}
.line {
	border-radius: 2px;
	width: 14px;
	height: 2px;
}
.first-line {
	transform: translateX(7px) rotate(45deg);
}
.last-line {
	transform: translateX(-7px) rotate(-45deg);
}
</style>
